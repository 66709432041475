import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import styled from 'styled-components';
import NotFound from '../../assets/svgs/not-found.svg';

const Wrapper = styled.div`
  display: flex;
  padding: 15vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage: React.FunctionComponent = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <NotFound />
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
